<template>
    <div>
        <v-col class="my-0 py-0 px-0 px-md-8 pb-15">
            <v-col class="text-start mt-5 pb-0">
                <v-alert text dense color="#8061c2" border="left" class="pl-5">
                    <h1 class="title mt-1">Monetización</h1>
                    <p class="desc mt-0 mb-1">Modifica la monetización de los harmonys.</p>
                </v-alert>
            </v-col>

            <!-- Search Bar y Filtros -->
            <v-row class="mx-3">
                <v-col cols="12" md="4" class="px-0">
                    <v-text-field v-model="searchTable" color="#8061c2" label="Buscar" append-icon="mdi-magnify"
                        single-line hide-details outlined class="mb-2 mx-0 px-0"></v-text-field>
                </v-col>

                <v-col cols="12" md="4" class="px-md-2 px-0">
                    <v-select v-model="filter" :items="filterOptions" label="Filtrar por" outlined
                        hide-details></v-select>
                </v-col>

                <v-col cols="12" md="4" class="px-0 ">
                    <v-select v-model="order" :items="orderOptions" label="Ordenar" outlined hide-details></v-select>
                </v-col>
            </v-row>

            <!-- Tabla de datos -->
            <v-data-table :headers="headers" :items="harmonys" :loading="loading" class="elevation-1 mx-3 px-2 py-4"
                :items-per-page="perPage" :server-items-length="totalSites" :page.sync="page" :footer-props="{
                    itemsPerPageOptions: [50, 100, 200, 300, -1], 
                    showFirstLastPage: true
                }" @update:page="fetchHarmonys" @update:items-per-page="changePerPage">
                <!-- Columnas y Templates -->
                <template v-slot:item.logo_path="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-avatar size="35" class="clickable-avatar" @click="showLogo(item.logo_path)"
                                @mouseover="hoveredLogo = item.logo_path" @mouseleave="hoveredLogo = null"
                                v-bind="attrs" v-on="on">
                                <v-img :src="item.logo_path" v-if="item.logo_path"></v-img>
                                <v-img :src="require('../../assets/illustrations/no-photo.jpg')" v-else></v-img>
                            </v-avatar>
                        </template>
                        <span>Ver Imagen</span>
                    </v-tooltip>
                </template>

                <template v-slot:item.visits="{ item }">
                    {{ item.abbreviated_visits || 0 }}
                </template>

                <template v-slot:item.name="{ item }">
                    {{ item.name }}
                </template>

                <template v-slot:item.monetization_type_names="{item}">
                    {{ nameMonetization(item.monetization_type_names) }}
                </template>

                <!-- Botones de acciones -->
                <template v-slot:item.actions="{ item }">
                    <!-- Botón para estadísticas -->

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon :color="item.monetization_type_names.includes('no_monetize') ? 'red' : '#4caf50'" v-bind="attrs" v-on="on" @click="openEditDialog(item)">
                                <v-icon size="35">mdi-cash-edit</v-icon>
                            </v-btn>
                        </template>
                        <span>Modificar <br>Monetizacion</span>
                    </v-tooltip>
                </template>
            </v-data-table>


            <MonetizationEdit
            :dialogShow.sync="editDialog"
             @close="editDialog = false"
            :idHarmony="harmonyInfo.idHarmony"
            :harmonyName.sync="harmonyInfo.harmonyName"
            :harmonyTypeMonetization.sync="harmonyInfo.harmonyTypeMonetization"
            :linksHarmony.sync="harmonyInfo.linksHarmony"
            :linksHarmonyMonetizable.sync="harmonyInfo.linksHarmonyMonetizable"
            :typesMonetization="typesMonetization"
            @update-monetization="updatedMonetization"
            />

            <!-- Modal para ampliar el logo -->
            <v-dialog v-model="dialog" max-width="14rem" class="rounded-lg">
                <v-img :src="selectedLogo" width="14rem"></v-img>
            </v-dialog>
        </v-col>
    </div>
</template>

<script>
import axios from '@/axios.js';
import debounce from 'lodash/debounce';
import MonetizationEdit from '../../components/partials/admin/MonetizationEdit.vue';

export default {
    name: "Harmonys",
    components: {
        MonetizationEdit
    },
    data() {
        return {
            harmonys: [],
            search: '',  // Término de búsqueda
            loading: true,
            dialog: false,
            selectedLogo: null,
            page: 1,
            perPage: 50,
            totalSites: 0,
            order: 'desc',  // Orden por defecto
            filter: 'created_at',  // Filtro por defecto
            editDialog: false,
            typesMonetization: [
                { text: 'Clicks Únicos', value: 'unique_clicks' },
                { text: 'Vistas Únicas', value: 'unique_views' },
                { text: 'Sin Monetización', value: 'no_monetize' }
            ],


            headers: [
                { text: 'Logo', value: 'logo_path', align: 'start', sortable: false },
                { text: 'Nombre', value: 'site_name', align: 'start' },
                { text: 'Creador', value: 'user_email' },
                { text: 'Visitas', value: 'total_visits', sortable: true },
                { text: 'Monetización', value: 'monetization_type_names', sortable: true },
                { text: 'Acciones', value: 'actions', sortable: false }
            ],
            orderOptions: [
                { text: 'Mayor', value: 'desc' },
                { text: 'Menor', value: 'asc' }
            ],
            filterOptions: [
                { text: 'Fecha Creación', value: 'created_at' },
                { text: 'Vistas', value: 'views' }
            ],

            harmonyInfo: {
                harmonyName: '',
                idHarmony: 0,
                harmonyTypeMonetization: [],
                linksHarmony: [],
                linksHarmonyMonetizable: [],
            }
        };
    },

    watch: {
        order(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.fetchHarmonys();
            }
        },
        filter(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.fetchHarmonys();
            }
        },
    },

    computed: {
        searchTable: {
            get() { return this.search },
            set: debounce(function (value) {
                this.search = value
                this.fetchHarmonys();
            }, 400)
        }
    },

    created() {
        this.initializeSearch();
        this.fetchHarmonys();
        this.$i18n.locale = 'es';
    },


    methods: {
        async updatedMonetization(harmonyTypeMonetization, linksHarmonyMonetizable, idHarmony) {
            try {
                const response = await axios.post('/admin/update-site-monetization', {
                    site_id: idHarmony,
                    monetization_type_names: harmonyTypeMonetization,
                    links_monetizable: linksHarmonyMonetizable
                });

                if (response.data.success) {
                    this.editDialog = false;
                    this.fetchHarmonys();
                    this.$vs.notify({
                        title: 'Éxito',
                        text: 'Monetización actualizada correctamente',
                        color: 'success',
                        icon: 'check_box',
                        position: 'bottom-center',
                        time: 5000,
                    });
                }

            } catch (error) {
                this.$vs.notify({
                    title: 'Error',
                    text: 'Error al actualizar la monetización',
                    color: 'danger',
                    icon: 'error',
                    position: 'bottom-center',
                    time: 5000,
                });
                console.error(error);
            }
        },

        openEditDialog(harmony) {
            this.harmonyInfo.idHarmony = harmony.site_id;
            this.harmonyInfo.harmonyTypeMonetization = harmony.monetization_type_names.split(',').map(mon => mon.trim());
            this.harmonyInfo.linksHarmony = harmony.links;
            this.harmonyInfo.linksHarmonyMonetizable = harmony.links_monetizable;
            this.harmonyInfo.harmonyName = harmony.site_name;
            this.editDialog = true;
        },

        initializeSearch() {
            const searchQuery = this.$route.query.search;
            if (searchQuery) {
                this.search = searchQuery;
            }
        },

        async fetchHarmonys() {
            this.loading = true;
            try {
                const response = await axios.get('/admin/get-sites-monetization', {
                    params: { 
                        page: this.page, 
                        per_page: this.perPage,
                        search: this.search ,
                        order: this.order,
                        filter: this.filter
                    }
                });

                this.harmonys = response.data.data;
                this.totalSites = response.data.total;

            } catch (error) {
                console.error(error);
            } finally {
                this.loading = false;
            }
        },

        changePerPage(newPerPage) {
            this.perPage = newPerPage;
            this.page = 1;
            this.fetchHarmonys();
        },

        showLogo(logoPath) {
            this.selectedLogo = logoPath ? logoPath : require('../../assets/illustrations/no-photo.jpg');
            this.dialog = true;
        },

        getStatusColor(statusId) {
            switch (statusId) {
                case 1: return 'orange';
                case 2: return 'gray';
                case 3: return 'rgb(101 201 105)';
                case 4: return '#ff756a';
                default: return 'blue';
            }
        },


        goToHarmony(slug) {
            const harmonyUrl = `${window.location.origin}/${slug}`;
            window.open(harmonyUrl, '_blank');
        },

        goToLinks(slug){
            const routeData = this.$router.resolve({
                name: 'Links',
                query: { search: slug }
            });

            window.open(routeData.href, '_blank');
        },

        nameMonetization(monetization) {
            return monetization.split(',').map(mon => {
                switch (mon.trim()) {
                    case 'unique_clicks':
                        return 'Clicks Únicos';
                    case 'unique_views':
                        return 'Vistas Únicas';
                    case 'no_monetize':
                        return 'Sin Monetización';
                    default:
                        return mon.trim();
                }
            }).join(', ');
        },

    }
};
</script>

<style scoped>
.title {
    font-size: 22px !important;
    color: #8061c2;
}

.description-news {
    color: #7C7C7C;
    font-weight: 400;
    font-size: 14px;
}

.desc {
    color: #737373;
    font-size: 15px;
}

.clickable-avatar:hover {
    transform: scale(1.2);
    transition: transform 0.3s ease;
}

.title-emails {
    color: #737373;
    font-weight: 800;
    font-size: 20px;
}

.harmony-name {
    color: #8061c2;
    font-weight: 800;
    font-size: 18px;
}
</style>
